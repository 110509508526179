@import "../theme/variables";
$datepickerDayCellSize: 32px !default;
$datepickerWidth: 250px !default;
$datepickerMinBodyHeight: 170px !default;
$datepickerBorderRadius: 8px !default;
$datepickerPadding: 4px !default;
$datepickerZIndex: 100 !default;
$datepickerFontSize: 14px !default;
$datepickerYearsPerRow: 4 !default;
//$light-font   :#ab8ce4;
$white :#fff;
$datepickerTextColor: (
        button: #5cc4ef,
        otherMonth: #dedede,
        otherMonthInRange: #ccc,
        disabled: #aeaeae,
        currentDate: #4EB5E6,
        common: #4a4a4a,
        dayNames: #FF9A19,
        navArrows: #9c9c9c
) !default;
$datepickerBG: (
        selected: #5cc4ef,
        selectedHover: darken(#5cc4ef, 5),
        inRange: rgba(#5cc4ef, .1),
        hover: #f0f0f0
) !default;
$datepickerBorderColor: (
        nav: #efefef,
        inline: #d7d7d7,
        default: #dbdbdb
) !default;
$datepickerNavigationHeight: 32px !default;
$datepickerNavigationButtonsOffset: 2px !default;
$datepickerPointerSize: 10px !default;
$datepickerPointerOffset: 10px !default;
// Transitions
$datepickerTransitionSpeed: .3s !default;
$datepickerTransitionEase: ease !default;
$datepickerTransitionOffset: 8px !default;
// Objects
%otherMonth {
  color: map_get($datepickerTextColor, otherMonth);
  &:hover {
    color: darken(map_get($datepickerTextColor, otherMonth), 10);
  }
  &.-disabled- {
    &.-focus- {
      color: map_get($datepickerTextColor, otherMonth);
    }
  }
  &.-selected- {
    color: #fff;
    background: lighten(map_get($datepickerBG, selected), 15);
    &.-focus- {
      background: lighten(map_get($datepickerBG, selected), 10);
    }
  }
  &.-in-range- {
    background-color: map_get($datepickerBG, inRange);
    color: darken(map_get($datepickerTextColor, otherMonth), 7);
    &.-focus- {
      background-color: rgba(map_get($datepickerBG, inRange), .2);
    }
  }
  &:empty {
    background: none;
    border: none;
  }
}
/* -------------------------------------------------
    Datepicker cells
   ------------------------------------------------- */
.datepicker--cells {
  display: flex;
  flex-wrap: wrap;
}
.datepicker--cell {
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: $datepickerDayCellSize;
  z-index: 1;
  &.-focus- {
    background: map_get($datepickerBG, hover);
  }
  &.-current- {
    color: map_get($datepickerTextColor, currentDate);
    border-color: map_get($datepickerTextColor, currentDate);
    &.-focus- {
      color: map_get($datepickerTextColor, common);
    }
    &.-in-range- {
      color: map_get($datepickerTextColor, currentDate);
    }
  }
  &.-in-range- {
    background: map_get($datepickerBG, inRange);
    color: map_get($datepickerTextColor, common);
    border-radius: 0;
    &.-focus- {
      background-color: rgba(map_get($datepickerBG, inRange), .2);
    }
  }
  &.-disabled- {
    cursor: default;
    color: map_get($datepickerTextColor, disabled);
    &.-focus- {
      color: map_get($datepickerTextColor, disabled);
    }
    &.-in-range- {
      color: darken(map_get($datepickerTextColor, disabled), 5);
    }
    &.-current- {
      &.-focus- {
        color: map_get($datepickerTextColor, disabled);
      }
    }
  }
  &.-range-from- {
    border: 1px solid rgba(map_get($datepickerBG, selected), .5);
    background-color: map_get($datepickerBG, inRange);
    border-radius: $datepickerBorderRadius 0 0 $datepickerBorderRadius;
  }
  &.-range-to- {
    border: 1px solid rgba(map_get($datepickerBG, selected), .5);
    background-color: map_get($datepickerBG, inRange);
    border-radius:  0 $datepickerBorderRadius $datepickerBorderRadius 0;
  }
  &.-range-from-.-range-to- {
    border-radius: $datepickerBorderRadius;
  }
  &.-selected- {
    color:$white;   
    border: none;   
    background-color: $primary-color;
    &.-current- {
      color:$white;          
      background-color: $primary-color;
    }
    &.-focus- {     
      background-color: $primary-color;
    }
  }
  &:empty {
    cursor: default;
  }
}
//  Day names
// -------------------------------------------------
.datepicker--days-names {
  display: flex;
  flex-wrap: wrap;
  margin: 8px 0 3px;
}
.datepicker--day-name {
  color: map_get($datepickerTextColor, dayNames);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: .8em;
}
//  Day cell
// -------------------------------------------------
.datepicker--cell-day {
  width: 14.2857142857%;
  height: 34px;
  &.-other-month- {
    @extend %otherMonth;
  }
}
//  Months
// -------------------------------------------------
.datepicker--months {}
.datepicker--cells-months {
  height: $datepickerMinBodyHeight;
}
//  Month cell
// -------------------------
.datepicker--cell-month {
  width: 33.33%;
  height: 25%;
}
//  Years
// -------------------------------------------------
.datepicker--years {
  height: $datepickerMinBodyHeight;
}
.datepicker--cells-years {
  height: $datepickerMinBodyHeight;
}
//  Year cell
// -------------------------
.datepicker--cell-year {
  width: calc(100% / $datepickerYearsPerRow);
  height: 33.33%;
  &.-other-decade- {
    @extend %otherMonth;
  }
}
/* -------------------------------------------------
    Datepicker
   ------------------------------------------------- */
.datepickers-container {
  position: absolute;
  left: 0;
  top: 0;
  @media print {
    display: none;
  }
}
.datepicker {
  background-color:$white;
  // border:1px solid $light-gray;
  // border: 1px solid map_get($datepickerBorderColor, default);
  border-radius: $datepickerBorderRadius;
  box-sizing: content-box;
  font-size: $datepickerFontSize;
  color: map_get($datepickerTextColor, common);
  width: $datepickerWidth;
  position: absolute;
  left: -100000px;
  opacity:0;
  padding:10px;
  transition: opacity $datepickerTransitionSpeed $datepickerTransitionEase, transform $datepickerTransitionSpeed $datepickerTransitionEase, left 0s $datepickerTransitionSpeed;
  z-index: $datepickerZIndex;
  box-shadow: 0 4px 14px rgba(174,197,231,.5);
  &.-from-top- {
    transform: translateY(-$datepickerTransitionOffset);
  }
  &.-from-right- {
    transform: translateX($datepickerTransitionOffset);
  }
  &.-from-bottom- {
    transform: translateY($datepickerTransitionOffset);
  }
  &.-from-left- {
    transform: translateX(-$datepickerTransitionOffset);
  }
  &.active {
    opacity: 1;
    transform: translate(0);
    transition: opacity $datepickerTransitionSpeed $datepickerTransitionEase, transform $datepickerTransitionSpeed $datepickerTransitionEase, left 0s 0s;
  }
}
.datepicker-inline {
  .datepicker {   
    position:static;
    left:auto;
    right:auto;
    opacity:1;
    transform:none;
    border-color:$light-gray;
  }
  .datepicker--pointer {
    display: none;
  }
}
.datepicker--content {
  box-sizing: content-box;
  padding: $datepickerPadding;
  .-only-timepicker- & {
    display: none;
  }
}
//  Pointer
// -------------------------------------------------
$pointerHalfSize: 4;
.datepicker--pointer {
  position: absolute;
  background: #fff;
  border-top: 1px solid map-get($datepickerBorderColor, default);
  border-right: 1px solid map-get($datepickerBorderColor, default);
  width: $datepickerPointerSize;
  height: $datepickerPointerSize;
  z-index: -1;
  //  Main axis
  // -------------------------
  .-top-left- &, .-top-center- &, .-top-right- & {
    top: calc(100% - #{$pointerHalfSize});
    transform: rotate(135deg);
  }
  .-right-top- &, .-right-center- &, .-right-bottom- & {
    right: calc(100% - #{$pointerHalfSize});
    transform: rotate(225deg);
  }
  .-bottom-left- &, .-bottom-center- &, .-bottom-right- & {
    bottom: calc(100% - #{$pointerHalfSize});
    transform: rotate(315deg);
  }
  .-left-top- &, .-left-center- &, .-left-bottom- & {
    left: calc(100% - #{$pointerHalfSize});
    transform: rotate(45deg);
  }
  //  Secondary axis
  // -------------------------
  .-top-left- &, .-bottom-left- & {
    left: $datepickerPointerOffset;
  }
  .-top-right- &, .-bottom-right- & {
    right: $datepickerPointerOffset;
  }
  .-top-center- &, .-bottom-center- & {
    left: calc(50% - #{$datepickerPointerSize} / 2);
  }
  .-left-top- &, .-right-top- & {
    top: $datepickerPointerOffset;
  }
  .-left-bottom- &, .-right-bottom- & {
    bottom: $datepickerPointerOffset;
  }
  .-left-center- &, .-right-center- & {
    top: calc(50% - #{$datepickerPointerSize} / 2);
  }
}
//  Body
// -------------------------------------------------
.datepicker--body {
  display: none;
  &.active {
    display: block !important;
  }
}
/* -------------------------------------------------
    Navigation
   ------------------------------------------------- */
.datepicker--nav {
  color: $primary-color;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid map_get($datepickerBorderColor, nav);
  min-height: $datepickerNavigationHeight;
  padding: $datepickerPadding;
  .-only-timepicker- & {
    display: none;
  }
}
.datepicker--nav-title,
.datepicker--nav-action {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.datepicker--nav-action {
  width:$datepickerDayCellSize;
  border-radius: 5px;
  user-select: none; 
  &.-disabled- {
    visibility: hidden;
  }
  svg {
    width: 32px;
    height: 32px;
  }
  path {
    fill: none;
    stroke: $white;
    stroke-width: 2px;
  }
}
.datepicker--nav-title {
  border-radius: $datepickerBorderRadius;
  padding: 0 8px;
  i {
    font-style: normal;
    color: $primary-color;
    margin-left: 5px;
  }
  &:hover {
  }
  &.-disabled- {
    cursor: default;
    background: none;
  }
}
//  Buttons
// -------------------------------------------------
.datepicker--buttons {
  display: flex;
  padding: $datepickerPadding;
  border-top: 1px solid map_get($datepickerBorderColor, nav);
}
.datepicker--button {
  color: map_get($datepickerTextColor, currentDate);
  cursor: pointer;
  border-radius: $datepickerBorderRadius;
  flex: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  &:hover {
    color: map_get($datepickerTextColor, common);
    background: map_get($datepickerBG, hover);
  }
}