.datepicker--cell-year.-other-decade-, .datepicker--cell-day.-other-month- {
  color: #dedede;
}
.datepicker--cell-year.-other-decade-:hover, .datepicker--cell-day.-other-month-:hover {
  color: #c5c5c5;
}
.-disabled-.-focus-.datepicker--cell-year.-other-decade-, .-disabled-.-focus-.datepicker--cell-day.-other-month- {
  color: #dedede;
}
.-selected-.datepicker--cell-year.-other-decade-, .-selected-.datepicker--cell-day.-other-month- {
  color: #fff;
  background: #a2ddf6;
}
.-selected-.-focus-.datepicker--cell-year.-other-decade-, .-selected-.-focus-.datepicker--cell-day.-other-month- {
  background: #8ad5f4;
}
.-in-range-.datepicker--cell-year.-other-decade-, .-in-range-.datepicker--cell-day.-other-month- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc;
}
.-in-range-.-focus-.datepicker--cell-year.-other-decade-, .-in-range-.-focus-.datepicker--cell-day.-other-month- {
  background-color: rgba(92, 196, 239, 0.2);
}
.datepicker--cell-year.-other-decade-:empty, .datepicker--cell-day.-other-month-:empty {
  background: none;
  border: none;
}

/* -------------------------------------------------
    Datepicker cells
   ------------------------------------------------- */
.datepicker--cells {
  display: flex;
  flex-wrap: wrap;
}

.datepicker--cell {
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 32px;
  z-index: 1;
}
.datepicker--cell.-focus- {
  background: #f0f0f0;
}
.datepicker--cell.-current- {
  color: #4EB5E6;
  border-color: #4EB5E6;
}
.datepicker--cell.-current-.-focus- {
  color: #4a4a4a;
}
.datepicker--cell.-current-.-in-range- {
  color: #4EB5E6;
}
.datepicker--cell.-in-range- {
  background: rgba(92, 196, 239, 0.1);
  color: #4a4a4a;
  border-radius: 0;
}
.datepicker--cell.-in-range-.-focus- {
  background-color: rgba(92, 196, 239, 0.2);
}
.datepicker--cell.-disabled- {
  cursor: default;
  color: #aeaeae;
}
.datepicker--cell.-disabled-.-focus- {
  color: #aeaeae;
}
.datepicker--cell.-disabled-.-in-range- {
  color: #a1a1a1;
}
.datepicker--cell.-disabled-.-current-.-focus- {
  color: #aeaeae;
}
.datepicker--cell.-range-from- {
  border: 1px solid rgba(92, 196, 239, 0.5);
  background-color: rgba(92, 196, 239, 0.1);
  border-radius: 8px 0 0 8px;
}
.datepicker--cell.-range-to- {
  border: 1px solid rgba(92, 196, 239, 0.5);
  background-color: rgba(92, 196, 239, 0.1);
  border-radius: 0 8px 8px 0;
}
.datepicker--cell.-range-from-.-range-to- {
  border-radius: 8px;
}
.datepicker--cell.-selected- {
  color: #fff;
  border: none;
  background-color: #24695c;
}
.datepicker--cell.-selected-.-current- {
  color: #fff;
  background-color: #24695c;
}
.datepicker--cell.-selected-.-focus- {
  background-color: #24695c;
}
.datepicker--cell:empty {
  cursor: default;
}

.datepicker--days-names {
  display: flex;
  flex-wrap: wrap;
  margin: 8px 0 3px;
}

.datepicker--day-name {
  color: #FF9A19;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8em;
}

.datepicker--cell-day {
  width: 14.2857142857%;
  height: 34px;
}
.datepicker--cells-months {
  height: 170px;
}

.datepicker--cell-month {
  width: 33.33%;
  height: 25%;
}

.datepicker--years {
  height: 170px;
}

.datepicker--cells-years {
  height: 170px;
}

.datepicker--cell-year {
  width: 25%;
  height: 33.33%;
}
/* -------------------------------------------------
    Datepicker
   ------------------------------------------------- */
.datepickers-container {
  position: absolute;
  left: 0;
  top: 0;
}
@media print {
  .datepickers-container {
    display: none;
  }
}

.datepicker {
  background-color: #fff;
  border-radius: 8px;
  box-sizing: content-box;
  font-size: 14px;
  color: #4a4a4a;
  width: 250px;
  position: absolute;
  left: -100000px;
  opacity: 0;
  padding: 10px;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s;
  z-index: 100;
  box-shadow: 0 4px 14px rgba(174, 197, 231, 0.5);
}
.datepicker.-from-top- {
  transform: translateY(-8px);
}
.datepicker.-from-right- {
  transform: translateX(8px);
}
.datepicker.-from-bottom- {
  transform: translateY(8px);
}
.datepicker.-from-left- {
  transform: translateX(-8px);
}
.datepicker.active {
  opacity: 1;
  transform: translate(0);
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s;
}

.datepicker-inline .datepicker {
  position: static;
  left: auto;
  right: auto;
  opacity: 1;
  transform: none;
  border-color: #e6edef;
}
.datepicker-inline .datepicker--pointer {
  display: none;
}

.datepicker--content {
  box-sizing: content-box;
  padding: 4px;
}
.-only-timepicker- .datepicker--content {
  display: none;
}

.datepicker--pointer {
  position: absolute;
  background: #fff;
  border-top: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  width: 10px;
  height: 10px;
  z-index: -1;
}
.-top-left- .datepicker--pointer, .-top-center- .datepicker--pointer, .-top-right- .datepicker--pointer {
  top: calc(100% - 4);
  transform: rotate(135deg);
}
.-right-top- .datepicker--pointer, .-right-center- .datepicker--pointer, .-right-bottom- .datepicker--pointer {
  right: calc(100% - 4);
  transform: rotate(225deg);
}
.-bottom-left- .datepicker--pointer, .-bottom-center- .datepicker--pointer, .-bottom-right- .datepicker--pointer {
  bottom: calc(100% - 4);
  transform: rotate(315deg);
}
.-left-top- .datepicker--pointer, .-left-center- .datepicker--pointer, .-left-bottom- .datepicker--pointer {
  left: calc(100% - 4);
  transform: rotate(45deg);
}
.-top-left- .datepicker--pointer, .-bottom-left- .datepicker--pointer {
  left: 10px;
}
.-top-right- .datepicker--pointer, .-bottom-right- .datepicker--pointer {
  right: 10px;
}
.-top-center- .datepicker--pointer, .-bottom-center- .datepicker--pointer {
  left: calc(50% - 10px / 2);
}
.-left-top- .datepicker--pointer, .-right-top- .datepicker--pointer {
  top: 10px;
}
.-left-bottom- .datepicker--pointer, .-right-bottom- .datepicker--pointer {
  bottom: 10px;
}
.-left-center- .datepicker--pointer, .-right-center- .datepicker--pointer {
  top: calc(50% - 10px / 2);
}

.datepicker--body {
  display: none;
}
.datepicker--body.active {
  display: block !important;
}

/* -------------------------------------------------
    Navigation
   ------------------------------------------------- */
.datepicker--nav {
  color: #24695c;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
  min-height: 32px;
  padding: 4px;
}
.-only-timepicker- .datepicker--nav {
  display: none;
}

.datepicker--nav-title,
.datepicker--nav-action {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.datepicker--nav-action {
  width: 32px;
  border-radius: 5px;
  user-select: none;
}
.datepicker--nav-action.-disabled- {
  visibility: hidden;
}
.datepicker--nav-action svg {
  width: 32px;
  height: 32px;
}
.datepicker--nav-action path {
  fill: none;
  stroke: #fff;
  stroke-width: 2px;
}

.datepicker--nav-title {
  border-radius: 8px;
  padding: 0 8px;
}
.datepicker--nav-title i {
  font-style: normal;
  color: #24695c;
  margin-left: 5px;
}
.datepicker--nav-title.-disabled- {
  cursor: default;
  background: none;
}

.datepicker--buttons {
  display: flex;
  padding: 4px;
  border-top: 1px solid #efefef;
}

.datepicker--button {
  color: #4EB5E6;
  cursor: pointer;
  border-radius: 8px;
  flex: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 32px;
}
.datepicker--button:hover {
  color: #4a4a4a;
  background: #f0f0f0;
}